import React from "react";
import Logo from "../ogms-logo-white-background.png"

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <img src={Logo} alt="Logo" style={{ width: '40%', borderRadius:"10px" }} />
                <p style={{ 
                      color: 'white', 
                      textShadow: '0px 0px 10px rgba(0, 0, 0, 0.8)', 
                      padding: '10px' 
                  }}>
                  {props.data ? props.data.paragraph : "Loading"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
